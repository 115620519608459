@import "../../../../global-sass/variables";

#contacts {
	h3 {
		color: $dark-color;
		margin: 1.2rem 0 .7rem; }

	.about-box {
		span.about-icon {
			height: 64px;
			width: 64px;
			display: block;
			padding: 17px;
			border-radius: 50px;
			background-color: $light-gray;
			border: 1px solid $dark-color;
			margin-bottom: 26px;
			transition: background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out;

			@media (max-width: $sm) {
				margin: auto; }

			img {
				filter: none;
				width: 100%;
				height: auto;
				transition: filter .3s ease-in-out; } }

		&:hover {
			span.about-icon {
				background-color: $dark-color;
				border: 1px solid $dark-color;
				transition: background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out;

				img {
					filter: invert(1);
					transition: filter .3s ease-in-out; } }

			.link {
				color: $dark-color; } }

		.link {
			color: $blue;
			text-decoration: underline; } } }
