@import "../../../../global-sass/variables";

#steps {
  .container {
    overflow: hidden; }

  h2 {
    margin-bottom: 200px;

    @media (max-width: $md) {
      margin-bottom: 120px!important; }

    @media (max-width: $sm) {
      margin-bottom: .5rem!important; } }

  h3 {
    margin: 1rem 0;
    margin-top: 12px;

    @media (max-width: $md) {
      margin-top: -8px; }

    @media (max-width: $sm) {
      margin-top: -4px; } }

  .work-step-box {
    margin-top: -150px;
    position: relative;
    padding: 0 60px;

    @media (max-width: $sm) {
      padding-right: calc(var(--bs-gutter-x) * .5);
      margin-top: 0; } }

  .work-step-box {
    &:before {
      position: absolute;
      left: 0;
      top: 250px;
      content: ' ';
      display: inline-block;
      width: 3px;
      height: 100%;
      background-color: $blue-gray;
      transition: background-color 1s, height 1s;

      @media (max-width: $sm) {
        left: 28px;
        top: 200px; } }

    &::last-child {
      &:before {
        height: 0;
        background-color: $blue-gray; } } }

  .work-step-box-active {
    &:before {
      position: absolute;
      left: 0;
      top: 250px;
      background-size: 100% 100%;
      background-position: 50% 0%;
      height: 100%;
      background-color: $dark-color;
      transition: background-color 1s, height 1s;

      @media (max-width: $sm) {
        left: 28px;
        top: 200px; } }

    .circle-span {
      &:before {
        background-color: $blue-gray;
        border: 3px solid $dark-color; } } }

  .work-step-box-right {
    @media (max-width: $sm) {
      text-align: left; } }

  .work-step-box-right::before {
    @media (min-width: $md) {
      right: -3px;
      left: auto; } }

  img {
    height: 250px;
    width: auto;
    z-index: 2;
    position: relative;

    @media (max-width: $md) {
      padding-top: 84px; }

    @media (max-width: $sm) {
      height: 200px;
      padding-top: 30px; } }

  span.step-number {
    display: block;
    font-size: 117px;
    color: $blue-gray;
    text-transform: uppercase;
    line-height: 16px;
    z-index: 1;
    white-space: nowrap;

    @media (max-width: $md) {
      font-size: 63px;
      line-height: 27px; }
    @media (max-width: $sm) {
      font-size: 90px;
      line-height: 27px; } }

  .circle-span {
    &:before {
      position: absolute;
      left: -7px;
      content: ' ';
      display: inline-block;
      width: 17px;
      height: 17px;
      background-color: $light-gray;
      border: 3px solid $gray-border;
      z-index: 2;
      border-radius: 10px;
      transition: background-color 1s, border 1s;

      @media (max-width: $sm) {
        left: 21px; } } }

  .circle-span-right::before {
    @media (min-width: $md) {
      right: -10px;
      left: auto; } }

  .ref-span {
    position: relative;
    bottom: -200px; }

  .container {
    .row:last-child {

      .ref-span {
        bottom: 0px; }

      .work-step-box {
        &:before {
          background-color: $light-gray!important; } }

      .work-step-box-active {
        &:before {
          background-color: $light-gray!important; } } } } }
