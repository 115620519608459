@import "variables";

button.btn {
  border-radius: 0!important;
  padding: 10px 30px 10px 30px;
  text-transform: uppercase;
  transition: color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out,box-shadow .3s ease-in-out; }

.btn-outline-dark {
  color: $dark-color!important;
  border-color: $dark-color!important;
  background-color:#fff!important {}

  &:hover {
    color: #fff!important;
    background-color: $dark-color!important;
    border-color: $dark-color!important; } }

.btn-dark {
  color: #fff!important;
  background-color: $dark-color!important;
  border-color: $dark-color!important;

  &:hover {
    color: $dark-color!important;
    border-color: $dark-color!important; } }
