@import "../../global-sass/variables";

.header {
  position: sticky;
  top: 0;
  background-color: $dark-color;
  z-index: 3;

  @media (max-width: $sm) {
    position: fixed; }

  .container {
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: $md) {
      padding-top: 8px;
      padding-bottom: 8px; } }

  .navbar-collapse {
    padding-top: 15px;
    padding-bottom: 15px;
    @media (max-width: $md) {
      padding-bottom: 8px; } }

  .navbar-nav {
    margin-left: auto;

    @media (max-width: $md) {
      border-bottom: 1px solid $text-color; }

    a.nav-link {
      color: white;
      text-decoration: none;
      display: block;
      margin-right: 30px;
      padding: 0;
      text-transform: uppercase;
      font-size: 1.2rem;

      @media (max-width: $md) {
        color: white;
        margin-right: 0;
        display: block;
        padding: 15px 0;
        border-top: 1px solid $text-color; }

      &:active {
        color: $blue; }

      &:visited {
        color: white; }

      &:hover {
        color: $blue; } } }

  .navbar {
    padding: 0; }

  .navbar-brand {
    display: flex;
    flex-direction: column;
    color: white;
    padding: 0;

    @media (max-width: $md) {
      width: 70%; }

    &:hover {
      color: white; }

    .full-name {
      font-size: 1.5rem;
      color: white;

      @media (max-width: $md) {
        font-size: 1.2rem; } }

    .profession {
      font-size: 0.9rem;
      color: white;

      @media (max-width: $md) {
        white-space: break-spaces; } } }

  .navbar-toggler {
    background-color: $light-gray;
    border-radius: 0;
    padding: 0.5rem 0.75rem; } }
