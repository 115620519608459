@import "../../../../global-sass/variables";

.about-box {
  h3 {
    @media (max-width: $sm) {
      margin-bottom: .6rem; } }

  p {
    margin-bottom: .6rem; }

  span.about-icon {
    height: 64px;
    width: 64px;
    display: block;
    padding: 16px 18px 18px;
    border-radius: 50px;
    background-color: $light-gray;
    border: 1px solid $dark-color;
    margin-bottom: 26px;

    @media (max-width: $sm) {
      margin: auto; }

    img {
      width: 100%;
      height: auto; } } }

#about {
  .row {
    div {
      padding-top: 2rem;
      padding-bottom: 2rem;

      @media (max-width: $sm) {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem; } }
    p {
      color: $text-color;
      margin-bottom: 0; } } }

