@import "variables";

h1.header-1 {
  line-height: 3.5rem;
  font-size: 3rem;
  text-transform: uppercase;
  color: $dark-color;

  @media (max-width: $md) {
    font-size: 1.7rem;
    line-height: 2rem; }

  @media (max-width: $sm) {
    font-size: 1.6rem;
    line-height: 2rem; } }


h2 {
  font-size: 3rem;
  margin-bottom: 60px;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: $md) {
    font-size: 1.5rem; }

  @media (max-width: $sm) {
    font-size: 2rem;
    margin-bottom: 15px; } }

h3 {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: 1.2rem 0;

  @media (max-width: $md) {
    font-size: 1.5rem; } }

h4 {
  font-size: 2rem; }

p, span {
  color: $text-color; }

p, ul {
  color: $text-color; }
