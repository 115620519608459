@import "../../global-sass/variables";

.back-to-top {
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: 50px;
	height: 50px;
	font-size: 2rem;
	background: $dark-color;
	color: white;
	cursor: pointer;
	border: none;
	transition: background .3s ease-in-out;

	&:hover {
		background: $blue;
		transition: background .3s ease-in-out; } }
