@import "../../../../../global-sass/variables";

.expirience-section {
  h2 {
    margin-bottom: 7px;

    @media (max-width: $sm) {
      margin-bottom: 0; } }

  .row {
    padding: 30px 0;
    overflow: hidden;
    background: -moz-linear-gradient(0deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 51%),-moz-linear-gradient(0deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 51%);
    background: -webkit-linear-gradient(0deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 51%),-webkit-linear-gradient(0deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 51%);
    background: -ms-linear-gradient(0deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 51%),-ms-linear-gradient(0deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 51%);
    background: linear-gradient(0deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 51%),linear-gradient(0deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.2) 50%,rgba(0, 0, 0, 0) 51%);
    background-size: 1px 60px;

    @media (max-width: $sm) {
      p {
        font-size: .85rem;
        padding: 30px calc(var(--bs-gutter-x) * .5); } }

    .year-col {
      padding: 0;

      span {
        color: $dark-color; } }

    h3 {
      @media (max-width: $sm) {
        text-align: center; } } }

  .span-year {
    display: flex;
    height: 60px;
    text-align: center;
    align-content: flex-start;
    justify-content: center;
    flex-direction: column; }

  .expirience-box {
    position: absolute;
    margin-bottom: 0;
    border: 1px solid $gray-border;
    border-left: 3px solid $dark-color;
    background-color: white;
    width: 95.8%;
    padding: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 15px;
    text-align: center;

    @media (max-width: $sm) {
      width: 89.8%; } }

  .expirience-hidden-box {
    opacity: 0;
    @media (min-width: $md) {
      opacity: 1; } }

  .now_expirience::before {
    top: 100%;
    border: 3px dotted $dark-color;
    position: absolute;
    content: ' ';
    border-bottom: black;
    width: 100vw;
    height: 1px; } }
