@import "../../../../global-sass/variables";

#numbers {
  .container {
    text-align: center;

    h3 {
      font-size: 4rem;
      margin: 0;
      text-transform: none;

      @media (max-width: $sm) {
        font-size: 2rem; } }

    .counter-box {
      padding: 60px 15px;

      @media (max-width: $md) {
        padding: 30px calc(var(--bs-gutter-x) * .5);
        margin-bottom: calc(var(--bs-gutter-x) * .5); }

      @media (max-width: $sm) {
        padding: 30px calc(var(--bs-gutter-x) * .5);
        margin-bottom: calc(var(--bs-gutter-x) * .5); }

      p {
        margin-bottom: 0;
        @media (max-width: $md) {
          font-size: .8rem; } } } } }
