@import "../../../../../global-sass/variables";

.lg-backdrop {
  background-color: $dark-color; }

.lg-outer {
	.lg-thumb-item {
		border-radius: 0; }

	.lg-thumb-outer {
		background-color: $dark-color; }

	.lg-thumb-item {
		&:hover {
			border-color: $blue-gray!important; }

		&:active {
			border-color: $blue!important; }

		&:focus {
			border-color: $blue!important; } }

	.lg-thumb-item.active {
		&:hover {
			border-color: $blue-gray!important; }

		&:active {
			border-color: $blue!important; }

		&:focus {
			border-color: $blue!important; } } }

.lg-download {
	display: none; }

.gallery-item {
	cursor: pointer;
	filter: none;
	transition: filter .3s ease-in-out;

	&:hover {
		filter: brightness(0.8);
		transition: filter .3s ease-in-out; } }

#portfolio {
	padding-bottom: 0;
	.shadow-box,.gallery-item {
		cursor: pointer;
		filter: none;
		transition: filter .3s ease-in-out;

		&:hover {
			filter: brightness(0.8);
			transition: filter .3s ease-in-out; } } }



