@import "buttons";
@import "variables";
@import "tables";
@import "typography";
@import "modal";

body {
  color: $dark-color!important;
  cursor: default;
  font-size: 1.1rem;

  @media (max-width: $md) {
    font-size: 0.9rem; }

  section {
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: $md) {
      padding-top: 60px;
      padding-bottom: 60px; }

    @media (max-width: $sm) {
      padding-top: 45px;
      padding-bottom: 45px; } }

  .bg-gray {
    background-color: $light-gray; }

  .bg-dark-gray {
    background-color: $gray; }

  .text-right {
    text-align: right; }

  a {
    text-decoration: none!important;
    transition: color .3s ease-in-out, text-decoration .3s ease-in-out;
    color: #5888cf;

    &:hover {
      transition: color .3s ease-in-out, text-decoration .3s ease-in-out;
      color: #235196; } }

  .shadow-box {
    background-color: white;
    border: 1px solid #e5e5e5;
    box-shadow: 5px 5px 20px #d7dde9; }

  .bordered-bottom {
    border-bottom: 3px solid #001221; }

  img {
    width: 100%; }

  .border-radius {
    border-radius: 0!important; }

  .price-scale-value {
    font-size: 1.1rem;
    font-family: Montserrat!important; } }

p, span {
  color: $text-color; }

.bg-with-lines {
  background: -moz-linear-gradient(90deg,rgba(0, 0, 0, 0) 49.9%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50.5%),-moz-linear-gradient(90deg,rgba(0, 0, 0, 0) 49.9%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50.5%);
  background: -webkit-linear-gradient(90deg,rgba(0, 0, 0, 0) 49.9%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50.5%),-webkit-linear-gradient(90deg,rgba(0, 0, 0, 0) 49.9%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50.5%);
  background: -ms-linear-gradient(90deg,rgba(0, 0, 0, 0) 49.9%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50.5%),-ms-linear-gradient(90deg,rgba(0, 0, 0, 0) 49.9%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50.5%);
  background: linear-gradient(90deg,rgba(0, 0, 0, 0) 49.9%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50.5%),linear-gradient(90deg,rgba(0, 0, 0, 0) 49.9%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50.5%);
  background-size: 180px 1px;
  background-position: 50% 50%;
  overflow: hidden;

  @media (max-width: $sm) {
    margin-top: 0;
    background: -moz-linear-gradient(90deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50%),-moz-linear-gradient(90deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50%);
    background: -webkit-linear-gradient(90deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50%),-webkit-linear-gradient(90deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50%);
    background: -ms-linear-gradient(90deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50%),-ms-linear-gradient(90deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50%);
    background: linear-gradient(90deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50%),linear-gradient(90deg,rgba(0, 0, 0, 0) 49%,rgba(0, 0, 0, 0.1) 50%,rgba(0, 0, 0, 0) 50%);
    background-size: 88px 1px;
    background-position: 50% 50%; } }


