$dark-color: #001221;
$light-gray: #fafafa;
$gray: #f1f1f1;
$text-color: #001221;
$gray-border: #e5e5e5;
$blue-gray: #EFEEF5;
$blue: #5888cf;

$font-primary: 'Montserrat';
$font-secondary: 'Playfair Display';
$box-shadow: 5px 5px 20px #d7dde9;

// media quries
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
