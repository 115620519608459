@import "../../../../global-sass/variables";
#price {
  .price-box {
    text-align: center;
    padding: 60px 15px;
    margin-bottom: 24px;
    box-shadow: $box-shadow;
    background-color: white;
    transition: background-color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out,box-shadow .3s ease-in-out;
    border: 1px solid $gray-border;

    @media (max-width: $sm) {
      padding: 15px;
      margin-bottom: 15px; }

    .price-value {
      font-size: 4rem;
      color: $dark-color;

      @media (max-width: $sm) {
        font-size: 3.3rem; } }

    &:hover {
      background-color: $dark-color;
      color: white;
      border: 1px solid $dark-color;
      transition: background-color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out,box-shadow .3s ease-in-out;

      .price-value, .price-scale-value, .price-scale, .price-period, .price-unit {
        color: white; }

      button {
        background-color: $dark-color!important;
        color: white!important;
        border-color: white!important;
        &:hover {
          background-color: white!important;
          color: $dark-color!important; } } }

    .price-scale {
      display: block; }

    button {
      margin-top: 24px;
      background-color: white!important;
      color: $dark-color!important;

      @media (max-width: $sm) {
        font-size: 1.1rem;
        width: 100%; } } }

  .conditions {
    margin-top: 60px;
    background-color: white;
    border-top: 1px solid $gray-border;
    border-bottom: 1px solid $gray-border;
    padding: 30px 15px;

    p {
      margin-bottom: 0; } } }



