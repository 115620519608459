@import "../../global-sass/variables";

.footer {
  background-color: $dark-color;
  color: white;
  padding: 30px 0;

  ul.ul_contacts {
    text-align: right;

    @media (max-width: $md) {
      text-align: left; }

    li {
      display: inline-block; } }

  ul {
    list-style: none;
    padding-left: 0;

    a {
      color: white;
      text-decoration: none; }
    li {
      margin-bottom: 15px;

      @media (min-width: $sm) {
        margin-bottom: 0; } } }

  .full-name {
    font-size: 1.5rem;
    color: white; }

  .profession {
    color: white;
    display: block;
    margin-bottom: 1rem;
    font-size: 0.9rem; }

  .about-icon {
    height: 40px;
    display: block;
    width: 40px;
    padding: 6px;
    border-radius: 20px;
    text-align: center;
    background-color: $dark-color;
    border: 1px solid $blue-gray;
    margin-right: 15px;
    transition: background-color .3s ease-in-out,border-color .3s ease-in-out;

    @media (max-width: $sm) {
      margin-right: 4px;
      margin-left: 4px;
      padding: 7px 6px 6px; }

    &:hover {
      background-color: $blue-gray;
      border: 1px solid $blue-gray;
      transition: background-color .3s ease-in-out,border-color .3s ease-in-out;

      img {
        filter: none;
        transition: filter .3s ease-in-out; } }

    img {
      height: 16px;
      filter: invert(1);
      transition: filter .3s ease-in-out; } } }
