@import "../../../../global-sass/variables";

.first-screen {
  margin-top: -84px;

  @media (max-width: $sm) {
    margin-top: 90px!important; }

  .texts {
    padding: 50px 70px 0 30px;
    text-align: right;

    @media (max-width: $sm) {
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
      text-align: left; }

    @media (max-width: $md) {
      padding: 50px 15px 0 15px; }

    p {
      width: 55%;
      margin: 2rem 0 2rem auto;

      @media (max-width: $md) {
        width: 100%;
        margin: 1rem 0; } }

    button {
      @media (max-width: $md) {
        margin-bottom: 4rem; } } }

  .img-wrapper {
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $sm) {
      height: auto; }

    .ela-photo {
      width: auto;
      height: 100%;
      padding-top: 50px;

      @media (max-width: $sm) {
        height: auto;
        width: 100%;
        padding-top: 0; } } }

  .margin-40 {
    margin-top: 0;
    padding: 2rem 0;

    @media (min-width: $md) {
      margin-top: -100px;
      padding: 0 calc(var(--bs-gutter-x) * .5); } }

  .name-box {
    position: relative;
    right: -15px;
    margin-top: -35vh;
    text-align: end;

    @media (max-width: $sm) {
      height: auto;
      margin-top: -54px;
      right: 0; }

    .full-name {
      background-color: $dark-color;
      color: white;
      font-size: 1.5rem;
      padding: 15px 30px;
      display: inline-block;

      @media (max-width: $md) {
        font-size: 1rem; } }

    .profession {
      border: 1px solid $gray-border;
      padding: 3px 30px 5px 15px;
      display: inline-block;
      text-align: right;
      background-color: $light-gray;

      @media (min-width: $md) {
        font-size: .8rem; }

      @media (min-width: $lg) {
        font-size: 1rem; } } }


  .ul_contacts {
    list-style: none;
    display: inline-block;
    padding-left: 0;

    a {
      width: 40px;
      height: 40px;
      padding: 10px;
      border-radius: 20px;
      display: block;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-left: 10px;
      border: 1px solid $dark-color;
      background-color: $light-gray!important;
      transition: border .3s ease-in-out,background-color .3s ease-in-out;

      @media (max-width: $sm) {
        margin-left: 3px;
        margin-right: 3px; }

      img {
        filter: invert(0);
        position: relative;
        top: -2px; }

      &:hover {
        background-color: $dark-color!important;
        transition: border .3s ease-in-out,background-color .3s ease-in-out;

        img {
          filter: invert(1); } }

      li {
        display: inline-block; } } }

  div.icons {
    padding: 30px 52px 30px 18px;
    position: relative;
    text-align: right;

    @media (max-width: $md) {
      padding: 30px 67px 30px 18px; }

    @media (max-width: $sm) {
      text-align: center;
      padding: 30px 0; } } }
